<template>
  <div>
    <main>
      <Article>
        <template v-slot:swirl>
          <swirl :pageId="89" />
        </template>

        <template v-slot:article__header>
          <ArticleHeader
            :heading="`${gql.data.page.title}`"
            :copy="`${gql.data.page.acf.subtitle}`"
          />
        </template>

        <template v-slot:article__image>
          <visual>
            <img
              :src="gql.data.page.featuredImage.node.sourceUrl"
              :alt="gql.data.page.featuredImage.node.altText"
            />
          </visual>
        </template>

        <template v-slot:content__copy>
          <copy v-html="gql.data.page.content" />
        </template>
      </Article>
    </main>
    <Footer>
      <template v-slot:links>
        <links :links="links" />
      </template>
    </Footer>
  </div>
</template>

<script>
import Article from "../components/Article.vue";
import ArticleHeader from "../components/ArticleHeader.vue";
import Visual from "../components/ArticleImage.vue";

import Copy from "../components/ContentCopy.vue";
import Links from "../components/Links.vue";
import Footer from "../components/Footer.vue";
import Swirl from "../components/Swirl.vue";

export default {
  name: "CoronaProof",
  data: function () {
    return {
      links: [
        {
          name: null,
          copyText: "Lees meer over corona en sport",
          ctaText: "www.rijksoverheid.nl",
          url:
            "https://www.rijksoverheid.nl/onderwerpen/coronavirus-covid-19/cultuur-uitgaan-en-sport/sport",
        },
      ],
      gql: {
        data: {
          page: {
            id: "cG9zdDo4OQ==",
            content:
              '\n<p>Jouw gezondheid staat natuurlijk op de eerste plaats. Daarom houdt Fitmark zich aan de regels van het RIVM. Bij klachten blijf je thuis en tijdens de trainingen houdt iedereen zoveel mogelijk rekening met elkaar.</p>\n\n\n\n<div class="wp-block-image"><figure class="aligncenter size-large is-resized"><img loading="lazy" src="https://content.fitmark.nl/wp-content/uploads/2021/01/Corona-1024x1024.jpg" alt="" class="wp-image-149" width="332" height="332" srcset="https://content.fitmark.nl/wp-content/uploads/2021/01/Corona-1024x1024.jpg 1024w, https://content.fitmark.nl/wp-content/uploads/2021/01/Corona-300x300.jpg 300w, https://content.fitmark.nl/wp-content/uploads/2021/01/Corona-150x150.jpg 150w, https://content.fitmark.nl/wp-content/uploads/2021/01/Corona-768x768.jpg 768w, https://content.fitmark.nl/wp-content/uploads/2021/01/Corona-100x100.jpg 100w, https://content.fitmark.nl/wp-content/uploads/2021/01/Corona.jpg 1100w" sizes="(max-width: 332px) 100vw, 332px" /></figure></div>\n',
            title: "Covid",
            featuredImage: {
              node: {
                altText: "",
                sourceUrl:
                  "https://content.fitmark.nl/wp-content/uploads/2021/04/Nummer-5-LR-1024x683.jpg",
                __typename: "MediaItem",
              },
              __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
            },
            acf: {
              subtitle: "Verantwoord sporten",
              scrollto: null,
              __typename: "Page_Acf",
            },
            __typename: "Page",
          },
          ptSubscriptions: {
            nodes: [
              {
                id: "cG9zdDoxMjE=",
                title: "5 Lessenkaart",
                content:
                  '\n<h4 class="card__list-heading">5 Losse lessen</h4>\n\n\n\n<ul class="card__list"><li>45 min. per training</li><li>Train wanneer het jou uitkomt</li><li>Ideaal om kennis te maken met personal training</li><li>De prijs is incl. btw</li></ul>\n',
                acf: {
                  prijs: "260",
                  __typename: "PtSubscription_Acf",
                },
                __typename: "PtSubscription",
              },
              {
                id: "cG9zdDoxMjI=",
                title: "2 maanden",
                content:
                  '\n<h4 class="card__list-heading">Traject van 2 maanden</h4>\n\n\n\n<ul class="card__list"><li>2x p.w. sporten</li><li>45 min. per training</li><li>20 fitness trainingen in totaal</li><li>Ideaal om een paar kilo af te vallen</li></ul>\n\n\n<h4 class="card__list-heading">Extra&#8217;s</h4>\n\n\n<ul class="card__list"><li>10 min. Coaching voedingspatroon</li><li>1x p.w. sporten kan ook: € 276,- p.m.</li><li>Samen sporten met een vriend(in) is mogelijk (prijs in overleg).</li><li>De prijzen zijn incl. btw</li></ul>\n\n\n\n<p></p>\n',
                acf: {
                  prijs: "480",
                  __typename: "PtSubscription_Acf",
                },
                __typename: "PtSubscription",
              },
              {
                id: "cG9zdDoxMjM=",
                title: "4 maanden",
                content:
                  '\n<h4 class="card__list-heading">Traject van 4 maanden</h4>\n\n\n\n<ul class="card__list"><li>2x p.w. sporten</li><li>45 min. per training</li><li>40 fitness trainingen in totaal</li><li>Ideaal om flink wat kilo&#8217;s af te vallen</li></ul>\n\n\n<h4 class="card__list-heading">Extra&#8217;s</h4>\n<ul class="card__list">\n<li>10 min. Coaching voedingspatroon</li>\n<li>1x p.w. sporten kan ook: € 247,- p.m.</li>\n<li>Samen sporten met een vriend(in) is mogelijk (prijs in overleg)</li>\n<li>De prijzen zijn incl. btw</li>\n</ul>',
                acf: {
                  prijs: "430",
                  __typename: "PtSubscription_Acf",
                },
                __typename: "PtSubscription",
              },
            ],
            __typename: "RootQueryToPtSubscriptionConnection",
          },
          bcSubscriptions: {
            nodes: [
              {
                id: "cG9zdDoxMDQ=",
                title: "1x p.w. maandabonnement",
                content:
                  '\n<ul class="card__list"><li>Sporten in groepsverband</li><li>Ideaal voor 1x per week trainen</li><li>Geen inschrijfgeld</li><li>Maandelijks opzegbaar</li><li>De prijs is incl. btw</li></ul>\n',
                acf: {
                  prijs: "30",
                  __typename: "BcSubscription_Acf",
                },
                __typename: "BcSubscription",
              },
              {
                id: "cG9zdDoxMjA=",
                title: "‘Onbeperkt’ maandabonnement",
                content:
                  '\n<ul class="card__list"><li>Sporten in groepsverband</li><li>Ideaal voor max. 3x p.w. trainen</li><li>Geen inschrijfgeld</li><li>Maandelijks opzegbaar</li><li>De prijs is incl. btw</li></ul>\n\n\n\n<p></p>\n',
                acf: {
                  prijs: "40",
                  __typename: "BcSubscription_Acf",
                },
                __typename: "BcSubscription",
              },
            ],
            __typename: "RootQueryToBcSubscriptionConnection",
          },
          trainingdays: {
            nodes: [
              {
                id: "cG9zdDoyMTk=",
                title: "Donderdagavond",
                content: "\n<p><br><br><br></p>\n",
                acf: {
                  locatie: {
                    locatieLink:
                      "https://www.google.com/maps/place/52%C2%B024'46.1%22N+4%C2%B038'57.3%22E/@52.4127957,4.6470677,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x9b04b13bb611e5a7!8m2!3d52.4127957!4d4.6492564?hl=nl",
                    locatieNaam: "Eemstraat",
                    __typename: "Trainingday_Acf_Locatie",
                  },
                  timeslots: {
                    tijdvak1: "19:30 - 20:20",
                    tijdvak2: null,
                    tijdvak3: null,
                    tijdvak4: null,
                    __typename: "Trainingday_Acf_Timeslots",
                  },
                  __typename: "Trainingday_Acf",
                },
                featuredImage: {
                  node: {
                    altText: "",
                    uri: "/trainingsdag/donderdagavond/sint-joriveld-2/",
                    sourceUrl:
                      "https://content.fitmark.nl/wp-content/uploads/2021/04/Sint-joriveld-2-150x150.jpeg",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                __typename: "Trainingday",
              },
              {
                id: "cG9zdDoxMTI=",
                title: "Zaterdagochtend",
                content: "\n<p><br><br><br></p>\n",
                acf: {
                  locatie: {
                    locatieLink:
                      "https://www.google.nl/maps/place/Sint+Jorisveld,+2023+GD+Haarlem/@52.3940806,4.6368784,17z/data=!3m1!4b1!4m5!3m4!1s0x47c5ef7408ef82d7:0x2560aed4327b6158!8m2!3d52.3940773!4d4.6390671",
                    locatieNaam: "Sint Jorisveld",
                    __typename: "Trainingday_Acf_Locatie",
                  },
                  timeslots: {
                    tijdvak1: "9:30 - 10:20",
                    tijdvak2: null,
                    tijdvak3: null,
                    tijdvak4: null,
                    __typename: "Trainingday_Acf_Timeslots",
                  },
                  __typename: "Trainingday_Acf",
                },
                featuredImage: {
                  node: {
                    altText: "",
                    uri: "/trainingsdag/donderdagavond/sint-joriveld-2/",
                    sourceUrl:
                      "https://content.fitmark.nl/wp-content/uploads/2021/04/Sint-joriveld-2-150x150.jpeg",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                __typename: "Trainingday",
              },
              {
                id: "cG9zdDoxMTA=",
                title: "Dinsdagavond",
                content: "\n<p><br><br></p>\n",
                acf: {
                  locatie: {
                    locatieLink:
                      "https://www.google.nl/maps/place/Sint+Jorisveld,+2023+GD+Haarlem/@52.3940806,4.6368784,17z/data=!3m1!4b1!4m5!3m4!1s0x47c5ef7408ef82d7:0x2560aed4327b6158!8m2!3d52.3940773!4d4.6390671",
                    locatieNaam: "SInt Jorisveld",
                    __typename: "Trainingday_Acf_Locatie",
                  },
                  timeslots: {
                    tijdvak1: "19:30 - 20:20",
                    tijdvak2: null,
                    tijdvak3: null,
                    tijdvak4: null,
                    __typename: "Trainingday_Acf_Timeslots",
                  },
                  __typename: "Trainingday_Acf",
                },
                featuredImage: {
                  node: {
                    altText: "",
                    uri: "/trainingsdag/donderdagavond/sint-joriveld-2/",
                    sourceUrl:
                      "https://content.fitmark.nl/wp-content/uploads/2021/04/Sint-joriveld-2-150x150.jpeg",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                __typename: "Trainingday",
              },
            ],
            __typename: "RootQueryToTrainingdayConnection",
          },
          pijlers: {
            nodes: [
              {
                id: "cG9zdDo5OA==",
                title: "3. Plezier",
                content:
                  "\n<p>Fitmark vind het belangrijk dat je doet wat je leuk vindt. Dit noemen we ‘spelen’. Onder ‘spelen’ verstaan we het doen van een activiteit zonder specifiek doel, maar wel met plezier en betrokkenheid. ‘We zijn oud genoeg om jong te zijn’!</p>\n",
                __typename: "Pijler",
              },
              {
                id: "cG9zdDo5Nw==",
                title: "2. Natuur",
                content:
                  "\n<p>Wie zegt dat je fitness alleen kan beoefenen in de sportschool? Verschillende studies wijzen uit dat we ons krachtiger en positiever voelen als we sporten in de natuur. Het bewegen in de buitenlucht heeft een reducerend effect op de hoeveelheid cortisol in ons lichaam. Daarom is sporten in de natuur extra gezond!</p>\n",
                __typename: "Pijler",
              },
              {
                id: "cG9zdDo5Ng==",
                title: "1. Beweging",
                content:
                  "\n<p>Met beweging bedoel ik meer dan simpel een rondje fietsen of wandelen. Hiermee bedoel ik functionele fitness. Het heeft een krachtig effect op ons dagelijks functioneren en biedt ons de vrijheid om overal te kunnen gaan en staan. Functionele fitness is dan ook een uitstekend middel om je beweegkwaliteit te onderhouden.</p>\n",
                __typename: "Pijler",
              },
            ],
            __typename: "RootQueryToPijlerConnection",
          },
        },
      },
    };
  },
  components: {
    Article,
    ArticleHeader,
    Visual,
    Links,
    Footer,
    Copy,
    Swirl,
  },
};
</script>
