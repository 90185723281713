<template>
  <header class="article__header">
    <h1 class="article__heading">{{ heading }}</h1>
    <p class="article__header-copy">{{ copy }}</p>
  </header>
</template>

<script>
export default {
  name: "ArticleHeader",
  props: {
    heading: {
      type: String,
      default: "Bootcamp Training",
    },
    copy: {
      type: String,
      default: "Fitmark houdt het speels",
    },
  },
};
</script>
