<template>
  <div class="content__copy" @click.prevent="contentClick($event)">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ContentCopy",
  data: function () {
    return {};
  },
  methods: {
    contentClick(event) {
      if (event.target.nodeName === "A") {
        this.$emit("content-click", event);
      }
    },
  },
  mounted: function () {},
};
</script>
