<template>
  <div class="swirl">
    <div class="swirl__inner" :style="[styles[pageId]]" />
  </div>
</template>

<script>
export default {
  name: "Swirl",
  props: {
    pageId: Number,
  },
  data: function () {
    return {
      styles: {
        12: {
          background: "linear-gradient(to right, #119ca3 0%, #e1fafb 100%)",
        },
        42: {
          background: "linear-gradient(to right, #f3975a 0%, #fdf4ee 100%)",
        },
        2: {
          background: "linear-gradient(to right, #3a925e 0%, #e9f6ee 100%)",
        },
        48: {
          background: "linear-gradient(to right, #fbc558 0%, #fef9ee 100%)",
        },
        89: {
          background: "linear-gradient(to right, #9ac667 0%, #f4f9ef 100%)",
        },
        54: {
          background: "linear-gradient(to right, #185e7c 0%, #e0f1f9 100%)",
        },
        483: {
          background: "linear-gradient(to right, #fbc558 0%, #fef9ee 100%)",
        },
        550: {
          background: "linear-gradient(to right, #fbc558 0%, #fef9ee 100%)",
        },
      },
    };
  },
};
</script>
