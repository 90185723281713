<template>
  <footer class="footer">
    <slot name="links"></slot>
    <div class="footer__logo">
      <img
        class="footer__logo-vector"
        src="../assets/images/fitmark-logo.svg"
        width=""
        height=""
        alt="Fitmark - logo"
      />
    </div>
    <div class="footer__type-logo">
      <LogoType class="footer__type-logo-vector" />
      <div class="footer__socials">
        <a
          href="https://www.instagram.com/fitmark.personal.training/?hl=nl"
          class="footer__social-ig"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg>
            <use xlink:href="fa-brands.svg#instagram-square"></use>
          </svg>
        </a>
        <a
          href="https://www.facebook.com/Fitmark-115203639921747"
          class="footer__social-fb"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg>
            <use xlink:href="fa-brands.svg#facebook-square"></use>
          </svg>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=31657640930"
          class="footer__social-wa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg>
            <use xlink:href="fa-brands.svg#whatsapp"></use>
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoType from "./LogoType.vue";

export default {
  name: "Footer",
  components: {
    LogoType,
  },
};
</script>
