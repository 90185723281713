<template>
  <nav class="links">
    <a class="link" :href="routes[0]">
      <span class="link__description">
        <slot name="content__copy">
          {{ links[0].copyText }}
        </slot>
      </span>
      <span class="link__cta">
        {{ links[0].ctaText }}
      </span>
    </a>
    <slot>
      <router-link to="/contact">
        <span class="link__description">
          Stuur Mark een bericht
        </span>
        <span class="link__cta">
          Ga naar contact
        </span>
      </router-link>
    </slot>
  </nav>
</template>

<script>
export default {
  name: 'Links',
  props: {
    links: Array
  },
  computed: {
    routes () {
      const routes = this.links.map(link => {
        if (link.name) {
          const route = this.$router.resolve({
            name: link.name
          })

          return route.href
        } else {
          return link.url
        }
      })

      return routes
    }
  }
}
</script>
