<template>
  <section class="article main-grid">
    <!--
      by rendering shadow as the first child, its siblings
      will automatically render on top
    -->
    <div class="article__shadow"></div>

    <!-- <div class="swirl">
      <div class="swirl__inner" />
    </div> -->
    <slot name="swirl"></slot>

    <slot name="article__header"></slot>
    <slot name="article__image"></slot>

    <article class="content">
      <slot name="content__copy"></slot>
      <slot name="locations"></slot>
      <slot name="content__scroll"></slot>
    </article>

    <slot name="sidebar"></slot>
  </section>
</template>

<script>
export default {
  name: 'Article',
  methods: {
  },
  mounted: function () {
  }
}
</script>
