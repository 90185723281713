<template>
  <figure class="article__image">
    <slot>
      <img width="640" height="480" src="http://placekitten.com/640/485" />
    </slot>
  </figure>
</template>

<script>
export default {
  name: 'ArticleImage'
}
</script>
